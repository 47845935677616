'use client';

import {Fragment, useEffect} from "react";

async function initializeServiceWorker() {
  if ("serviceWorker" in navigator) {
    let registration = await navigator.serviceWorker.getRegistration()
    if (registration) {
      await registration.update();
    } else {
      registration = await navigator.serviceWorker.register("/sw.js", {
        updateViaCache: "none"
      });
    }

    await navigator.serviceWorker.ready

    if (registration.installing) {
      console.log("Service worker installing");
    } else if (registration.waiting) {
      console.log("Service worker installed");
    } else if (registration.active) {
      console.log("Service worker active");
    }

    navigator.serviceWorker.addEventListener('message', event => {
      console.log("received message 1", event)
      if (event.data && event.data.type === 'navigate') {
        console.log("received message", event.data)
        window.location.href = event.data.url;
      }
    });

    return registration
  } else {
    return undefined
  }
}

let _serviceWorker: Promise<ServiceWorkerRegistration|undefined>|undefined = undefined //= new Promise(resolve => initializeServiceWorker())

export async function serviceWorker(): Promise<ServiceWorkerRegistration|undefined> {
  if(!_serviceWorker) {
    _serviceWorker = initializeServiceWorker();
  }
  return _serviceWorker
}

export function InstallServiceWorker() {
  useEffect(() => {
    serviceWorker()
  })
  return <Fragment />
}
