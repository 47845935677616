"use client";
import {bugsnagStart} from "@/bugsnag";
import AbilityProvider from "@/app/pwa/lib/ability-context";
import {SessionProvider} from "next-auth/react";
import PreviousRouteProvider from "@/app/pwa/lib/previous-route-context";
import PWAProvider from "@/app/pwa/lib/pwa-context";
import {Club, Person, Team} from "@/sources/kicker-amateur/types";
import {ReactNode, useEffect} from "react";
import {Session} from "next-auth";
import SessionUpdateProvider from "@/app/pwa/lib/SessionUpdateProvider";
import { UsercentricsProvider } from '@s-group/react-usercentrics'

export interface ClientProviderProps {
  baseUrl?: string;
  children: ReactNode;
  pwa: Club;
  teams: Team[];
  persons: Person[];
  session: Session | null;
}

export default function ClientProvider({
  baseUrl,
  children,
  pwa,
  teams,
  persons,
  session,
}: ClientProviderProps) {
  useEffect(() => {
    bugsnagStart();
  }, []);

  return (
    <UsercentricsProvider windowEventName="ucEvent">
      <PWAProvider pwa={pwa} teams={teams} persons={persons}>
        <SessionProvider baseUrl={baseUrl} session={session}>
          <SessionUpdateProvider>
            <AbilityProvider pwaId={pwa.Id!} teams={teams}>
              <PreviousRouteProvider>{children}</PreviousRouteProvider>
            </AbilityProvider>
          </SessionUpdateProvider>
        </SessionProvider>
      </PWAProvider>
    </UsercentricsProvider>
  );
}
