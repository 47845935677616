"use client";

import OnboardingSlider from "@/app/pwa/(simple-layout)/welcome_appv2/components/OnboardingSlider";
import { Club, SquadPositionPlayer } from "@/sources/kicker-amateur/types";
import { useState, useEffect } from "react";

export default function OnboardingSliderHomeClientWrapper({pwa, player, teamId, contestId, homeUrl, teamColor, teamContrastColor} : {pwa: Club, player: SquadPositionPlayer, teamId: number, contestId: number, homeUrl: string, teamColor: string, teamContrastColor: string}) {
  const [showSlider, setShowSlider] = useState(false);

  useEffect(() => {
    //store this in local storage and reset after 30 days
    const lastShown = localStorage.getItem("onboardingSliderHomeLastShown");
    let lastShownDays = 40;
    if(lastShown) {
      const lastShownDate = new Date(lastShown);
      const diffTime = Math.abs(Date.now() - lastShownDate.getTime());
      lastShownDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    setShowSlider(lastShownDays > 30);
  }, []);
 
  return <>{showSlider && <div className="fixed inset-0 z-20 bg-primary text-gray-900"
    style={
      {
        "--team": teamColor,
        "--team-contrast": teamContrastColor,
      } as React.CSSProperties
    }
  >
    <OnboardingSlider pwa={pwa} player={player} teamId={teamId} contestId={contestId} homeUrl={homeUrl} onClose={() => {setShowSlider(false); localStorage.setItem("onboardingSliderHomeLastShown", new Date().toISOString());}}/>
  </div>
  }
  </>
}
