'use client';

import { Club } from "@/sources/kicker-amateur/types";
import { usePathname } from "next/navigation";
import {Fragment, useEffect} from "react";
import { useHasServiceConsent } from '@s-group/react-usercentrics'

const pianoAnalyticsTracker = require('piano-analytics-js').pianoAnalytics;

pianoAnalyticsTracker.setConfigurations({
    'site': 607680,
    'collectDomain': 'https://logs1408.xiti.com'
});

interface PianoAnalyticsProps {
  pwa?: Club;
}

export function PianoTracking({ pwa }: PianoAnalyticsProps) {
  const pathname = usePathname();
  const isConsentGiven = useHasServiceConsent('sriqFX5Ul')
  
  useEffect(() => {
    if(isConsentGiven) {
      pianoAnalyticsTracker.sendEvent('page.display', // Event name
          {
              page: pathname,
              event_url: pathname,
              verein: pwa?.Name,
              clubId: pwa?.Id
          }
      );
    }
  }, [pathname, isConsentGiven, pwa]);
  return <Fragment />
}
