import {PersonFunction} from "@/app/api/auth/[...nextauth]/lib/authOptions";
import {ReactNode, useEffect, useState} from "react";
import {useSession} from "next-auth/react";
import {useRouter} from "next/navigation";

function personFunctionsEqual(a: PersonFunction[], b: PersonFunction[]): boolean {
  return (
    a.length === b.length &&
    a.every((val, index) => JSON.stringify(val) === JSON.stringify(b[index]))
  );
}

export default function SessionUpdateProvider({children}: { children: ReactNode }) {
  const {data: session, update} = useSession();
  const [personFunctions, setPersonFunctions] = useState<PersonFunction[]|undefined>(
    session?.user?.personFunctions
  );
  const router = useRouter();

  useEffect(() => {
    if (session && (!session.updatedAt || session.updatedAt < Date.now() - 1000 * 60)) {
      update();
    }
  }, []);

  useEffect(() => {
    if (personFunctions && !personFunctionsEqual(session?.user?.personFunctions || [], personFunctions)) {
      //console.log('Permission updated detected: refresh route', session?.user?.personFunctions, personFunctions)
      router.refresh();
    }
    // update person function on login
    if(!personFunctions && session?.user?.personFunctions) {
      setPersonFunctions(session?.user?.personFunctions)
    }
  }, [router, session, personFunctions]);

  return <>{children}</>;
}
